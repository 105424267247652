<template>
  <div class="index">
    <van-swipe class="my-swipe"
               :autoplay="3000"
               indicator-color="white">
      <van-swipe-item>
        <img src="../assets/banner1.jpg"
             style="width: 100%" />
      </van-swipe-item>
      <van-swipe-item>
        <img src="../assets/banner2.jpg"
             style="width: 100%" />
      </van-swipe-item>
    </van-swipe>
    <van-cell :title="item.text"
              is-link
              v-for="(item,index) in cellList"
              :key="index"
              :to="item.url"
              class="index-cell">
      <template #icon>
        <img :src="item.img"
             width="50px">
      </template>
    </van-cell>

  </div>
</template>

<script>
export default {
  name: 'Index',
  data () {
    return {
      cellList: [
        {
          img: require('../assets/fangjia.png'),
          text: '房价查询',
          url: 'fangjiact',
        },
        // {
        //   img: require('../assets/fangdai.png'),
        //   text: '房产抵押贷款申请',
        //   url: 'fangdaict',
        // },
        {
          img: require('../assets/jisuan.png'),
          text: '房贷计算器',
          url: 'jisuan',
        },
      ]
    };
  },
  created () {
    this.$store.state.tabBar = this.$route.name;
  },
  methods: {}
}
</script>
<style lang="less">
.index-cell {
  display: flex;
  align-items: center;

  .van-cell__title {
    padding-left: 10px;
  }
}
</style>

